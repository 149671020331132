import React, { useEffect } from 'react'
import Logo from "./partials/logo"

const Preloader = ({location}) => {
    useEffect(() => {
        if(!location?.state?.internal){
            setTimeout(function(){document.querySelector('.preloader').classList.add('loaded');}, 500);
            setTimeout(function(){document.querySelector('.preloader').classList.remove('loaded');}, 1500);
            setTimeout(function(){document.querySelector('.preloader').classList.add('fade-out');}, 2500);
        } else {
            document.querySelector('.preloader').style.display = 'none';
        }
    })
    return(
    <div className="preloader">
        <Logo />
    </div>
    )
}
export default Preloader
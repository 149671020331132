import React from 'react'
import { Link } from "gatsby"

const Assistance = ({title, tiles, subtitle}) =>{
    const cards = tiles.nodes?.map((tile, index) => {
        return ( 
        <div className={"tile flex p20 "+ (tile.whiteText === true ?  'light-text' : '')} key={index} style={{"background":tile.background?.rgb}}>
            <div className="p20 flex flex-wrap mp0">
                <h3 className="m0 normal mb60 m-mb20 max-275">{tile.title}</h3> 
                <div className="w-100 align-self--bottom">
                    <Link to={tile.link} className="button white">Read More</Link>
                </div>  
            </div>  
        </div>
        )
    }
    );
    return(
    <div className="assistance-grid bg-grey-200 p20"> 
        <div className="spacer m-hide"></div>
        <div className="title-area max-650 text-center ma m-pb20 m-mt40 m-mb40">
            <p className="h1 m0 mb40">{title}</p>
            <div className="h3" dangerouslySetInnerHTML={{__html : subtitle}}></div>
        </div>
        <div className="spacer m-hide"></div>
        <div className="tiles grid max-1200 ma">
            {cards}
        </div>
        <div className="spacer m-hide"></div>
    </div>
    )
}
export default Assistance
import React, { useEffect } from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Preloader from "../components/preloader"
import TextBanner from "../components/textBanner"
import ImageText from "../components/imageText"
import Assistance from "../components/assistance"
import Testimonials from "../components/testimonials"
import { graphql } from "gatsby"

const IndexPage = ({ data, location }) => {
  useEffect(() => {
    const interval = setInterval(scrollTracker, 200);
    return () => clearInterval(interval);
  }, []);
  const scrollTracker = () => {
    if (window.scrollY > 100) {
      document.querySelector('.masthead').classList.remove('transparent');
    } else {
      document.querySelector('.masthead').classList.add('transparent');
    }
  }

  const seoInfo = data.approach.edges[0].node
  return (
    <Layout page="Home">

      <Seo description={seoInfo.metaDescription} title={seoInfo.metaTitle} />

      <Preloader location={location} />

      <Banner image={data.hero.childImageSharp.gatsbyImageData} title={data.approach.edges[0].node.openingSectionText} />

      <TextBanner topIcon={data.approach.edges[0].node.introImage} button="Our Services" link="/services" text={data.approach.edges[0].node.ourServices} />

      <ImageText bottomIcon={data.approach.edges[0].node.approachIcon} bottomIconTwo={data.approach.edges[0].node.approachIconTwo} className="ratio-1-2-1" image={data.approach.edges[0].node.approachImage.gatsbyImageData} caption="Naomi Lewis, Legal Director & Principal Lawyer" title="Our Approach" button="Read More" link="/approach" text={`“${data.approach.edges[0].node.ourApproach}“`} />

      <Assistance tiles={data.allDatoCmsAssistanceTile} title="How can we assist you?" subtitle="If you’re dealing with a scenario not listed here, visit our <a href='/services' class='link ul-link reverse'>services</a> page, or contact us to see if we can be of assistance." />

      <Testimonials testimonials={data.allDatoCmsTestimonial} title="Testimonials" />

      <ImageText className="ratio-1-2-1" image={data.induction.childImageSharp.gatsbyImageData} background="#BEC2B5" reverse="flex-reverse" title="Online Induction" button="Get Started" link="https://lewisandtrovas.settify.com.au/landing?referral=homepage" text={data.approach.edges[0].node.onlineInduction} />

    </Layout>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    approach: allDatoCmsHomepage {
      edges {
        node {
          approachImage {
            gatsbyImageData
          }
          introImage {
            gatsbyImageData
          }
          approachIcon {
            gatsbyImageData
          }
          approachIconTwo {
            gatsbyImageData
          }
          openingSectionText
          onlineInduction
          ourApproach
          ourServices
          metaTitle
          metaDescription
        }
      }
    }
    induction: file(relativePath: { eq: "induction.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    allDatoCmsAssistanceTile(sort: {fields: order}) {
      nodes {
          title
          link
          whiteText
          background {
            rgb
          }
      }
    }
    allDatoCmsTestimonial {
      nodes {
        testimonials {
          quotation
          namePosition
        }
      }
    }
  }
`
export default IndexPage

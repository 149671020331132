import React from 'react'
import {GatsbyImage} from 'gatsby-plugin-image'
import Enquire from "./partials/enquire"  

const Banner = ({image, title}) =>{
    return(
        <div className="banner h-100 bg-grey pos-rel">
            <div className="title-area p20 text-white m-mta">
                <div className="max-600">
                    <h1 className="m0">{title}</h1>
                    <div className="m-show white-arrow m-mt40">
                        <Enquire mobile="true" /> 
                    </div>
                </div>
            </div>
            <GatsbyImage image={image} alt={title} className="bg-image" /> 
        </div>
    )
}
export default Banner
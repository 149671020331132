import React from 'react'
import Carousel from "react-flickity-component";
import 'flickity/dist/flickity.min.css';

const Testimonials = ({title, testimonials}) =>{
    const allTestimonial = testimonials.nodes[0]?.testimonials.map((testimonial, index) => {
        return ( 
        <div className="testimonial w-100" key={index}> 
            <div className="inner ma">
                <div className="testimonial h3 mb40">
                  “{testimonial?.quotation}”
                </div>
                <p className="m0 uppercase">{testimonial?.namePosition}</p> 
            </div>
             <div className="spacer"></div>
        </div>
        )
    });
    return(
    <div className="testimonials p20"> 
        <div className="spacer"></div>
        <div className="title-area max-650 text-center ma">
            <p className="h1 m0 mb40">{title}</p>
        </div>
        <div className="max-650 ma text-center">
            <Carousel 
              className="carousel--main"
              options={{
              pageDots: true,        
              prevNextButtons: false,
              autoPlay: true
            }}>
            {allTestimonial}
            </Carousel> 
        </div>
    </div>
    )
}
export default Testimonials